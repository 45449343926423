@media only screen and (min-width: 512px) {
    .container header .header-nav-area #nav_container  {
      display:flex;
        }
    .headerbox {
        display: flex;
        max-height: 200px;
        bottom: 0px;
        top: 0px;
    
    }

    .headerbox-left-box{
        background-color: #c23023;
        height: 6.22rem;
        width : 38.9rem;
        display: flex;
        justify-content: end;
        align-items: flex-end;
        padding: 5px;
    }
    .headerbox-right-box{
        background-color: white;
    }
    .headerbox-right-text{
        color : black;
        display: flex;
        justify-content: space-between;
        width: 50rem;
    }
    .header-label-1{
       
        padding: 2rem 0rem 0rem 1.3rem
    }
    .top-date{
            text-transform: uppercase;
            padding: 5px 5px 0px 5px;
            min-height: 21px;
            font-size: 21px;
            min-width: 1px;
            color: #333;
        
    }

    .header-datetime-date-text{
        font-family : 'Roboto', sans-serif;
        font-weight: 700 !important;
        color: #c23023;
        border: none !important;
    }
    .header-datetime-box{
       display: flex;
       align-items: center;
    }
    .header-datetime-month-text{
        border-left: 1px solid #9d9e9f;
        border-right: 1px solid #9d9e9f;
    }
    .logo-top{
   
        left: -45px;
        height: 4.3rem;
        margin-top: 1.3rem;
    }
    .logo-top-1{
        height: 4.3rem;
    }
    .logo-top-2{
        
        height: 2.5rem;
    }
    .logo-top-link{
        float:left;
        left:0%;
        margin-left: -220px;
        margin-top: 2%;
        font-size: 20px;
        font-weight: bold;
        top: 1%;

    }
    .title-logo{
        display: flex;
        flex-direction: column;
        justify-content: end;
        padding: 5px;
    }
}
@media only screen and (max-width: 512px) {

    .headerbox {
        display: flex;
        max-height: 100px;
        bottom: 0px;
        top: 0px;
    }

    .headerbox-left-box{
        background-color: #c23023;
        height: 6.22rem;
        width : 5rem;
        display: flex;
        justify-content: end;
        align-items: center;
        padding: 5px;
    }
    .headerbox-right-box{
        background-color: white;
    }
    .headerbox-right-text{
        color : black
    }
    .header-label-1{
        position: absolute;
        padding: 2rem 0rem 0rem 1.3rem
    }
    .top-date{
            text-transform: uppercase;
            padding: 5px 5px 0px 5px;
            min-height: 21px;
            font-size: 21px;
            min-width: 1px;
            color: #333;
        
    }

    .header-datetime-date-text{
        font-family : 'Roboto', sans-serif;
        font-weight: 700 !important;
        color: #c23023;
        border: none !important;
    }
    .header-datetime-box{
        visibility:hidden;
        top:2.5rem;
        align-items: right;
        justify-content: flex-end;
        right: 5rem;
    }
    .header-datetime-month-text{
        border-left: 1px solid #9d9e9f;
        border-right: 1px solid #9d9e9f;

    }
    .logo-top{
        
        left: -45px;
        height: 4.3rem;
        margin-top: 1.3rem;
    }
    .logo-top-1{
        height: 4.3rem;
    }
    .logo-top-2{
        
        height: 2.5rem;
    }
    .logo-top-link{
        float:left;
        left:0%;
        margin-left: -220px;
        margin-top: 2%;
        font-size: 20px;
        font-weight: bold;
        top: 1%;

    }
    .title-logo{
        display: flex;
        flex-direction: column;
        justify-content: end;
        padding: 5px;
    }
}