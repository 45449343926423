@media only screen and (min-width: 512px) {
  .access-form {
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }

  .access-row-1 {
    display: flex;
    width: 100%;
  }
  .acces-input-1 {
    width: 50%;
    padding: 1rem;
  }
}

@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
  .access-form {
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }
  .access-row-1 {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  .acces-input-1 {
    padding: 1rem;
    display: flex;
  }
}

@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .access-form {
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }
  .access-row-1 {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  .acces-input-1 {
    /*width: 50%;*/
    padding: 1rem;
    display: flex;
  }
}

@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .access-form {
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }
  .access-row-1 {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  .acces-input-1 {
    padding: 1rem;
    display: flex;
  }
}
