@media only screen and (min-width: 1100px) {

    .location-form{
        display: flex;
        flex-direction: column; 
        padding:1rem;
    }

    .location-row-1{
        display:flex;
        gap:1rem;
        margin-bottom: 1rem;
    }
    .location-input-1{
        width: 100%;
    }
    .location-input-2{
        width:100%
    }

    .location-row-2{
        display: flex;
        gap: 1rem;
    }
    .location-row-3{
        display: flex;
        gap: 1rem;
        margin-top: 2.5rem;

    }
    .location-input-3{
        width:15%;
        
    }
    .location-input-4{
        width:25%;
        
    }
    .hide-condition{
        display: none;
    }
    .show-condition{
        display: flex;
    }
    .bottom-list-text {
        margin-left: 1rem;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        width:100%;
        font-size: 18px;
    }.bottom-list-text-name {
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 18px;
    }.bottom-list-box{
        width:100%
    }.button-list-box{
        width:100%

    }
}
@media only screen and (min-width: 512px) and (max-width: 1100px) {

    .location-form{
        display: flex;
        flex-direction: column; 
        padding:1rem;
    }

    .location-row-1{
        display:flex;
        gap:1rem;
        margin-bottom: 1rem;
    }
    .location-input-1{
        width: 100%;
    }
    .location-input-2{
        width:100%
    }

    .location-row-2{
        display: flex;
        gap: 1rem;
        /* overflow: scroll; */
    }
    .location-row-3{
        display: flex;
        gap: 1rem;
        margin-top: 2.5rem;

    }
    .location-input-3{
        width:11%;
        
    }
    .location-input-4{
        width:25%;
        
    }
    .hide-condition{
        display: none;
    }
    .show-condition{
        display: flex;
    }
    .bottom-list-text {
        margin-left: 1rem;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        width:100%;
        font-size: 18px;
    }.bottom-list-text-name {
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 18px;
    }.bottom-list-box{
        width:100%
    }.button-list-box{
        width:100%

    }
}
@media only screen and (max-width: 512px) {
    .location-form{
        display: flex;
        flex-direction: column; 
        padding:1rem;
    }
    
    .location-row-1{
        display:flex;
        flex-direction: column; 
        gap:1rem;
        margin-bottom: 1rem;
    }
    .location-input-1{
        width: 100%;
    }
    .location-input-2{
        width:100%
    }
    
    .location-row-2{
        display: flex;
        flex-direction: column; 
        gap: 1rem;
    }
    .location-row-3{
        display: flex;
        flex-direction: column; 
        gap: 1rem;
        margin-top: 2.5rem;
    
    }
    .location-input-3{
        width:100%
    }
    .hide-condition{
        display: none;
    }
    .show-condition{
        display: flex;
    }
    .bottom-list-text {
        margin-left: 1rem;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        width:100%;
        font-size: 18px;
    }.bottom-list-text-name {
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 18px;
    }.bottom-list-box{
        width:100%
    }.button-list-box{
        width:100%
    
    }
}
