@media only screen and (min-width: 1300px) {
  .datos-usuario-box {
    display: flex;
    flex-direction: column;
    width: 95%;
  }

  .datos-usuario-text-box {
    display: flex;
    justify-content: flex-start;
    align-items: left;
    text-align: left;
    font-family: "Roboto", sans-serif;
    color: #333;
    font-size: 22px;
    line-height: 1.8;
  }

  .datos-usuario-text {
    display: inline-flex;
    flex-direction: row;
    gap: 1rem;
  }
  .datos-usuario-text-1 {
    font-weight: 600;
    padding-left: 0.5rem;
  }
  .datos-usuario-text-2 {
    color: #777;
    font-weight: lighter;
  }

  .datos-usuario-form-1 {
    border-top: 3px solid #d2d6de;
    border-radius: 0.2rem;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  }
  .datos-usuario-form-2 {
    padding-top: 5px;
    border-top: 3px solid #d2d6de;
    border-radius: 0.2rem;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  }
  .datos-usuario-text-box-1 {
    display: flex;
    justify-content: flex-start;
    align-items: left;
    text-align: left;
    font-family: "Roboto", sans-serif;
    color: #333;
    font-size: 20px;
    line-height: 1.8;
  }
  .datos-usuario-text-b {
    display: inline-flex;
    flex-direction: row;
    gap: 1rem;
  }
  .datos-usuario-text-3 {
    font-weight: 600;
    padding-left: 0.5rem;
  }

  .datos-usuario-icon-1 {
    padding-left: 1rem;
  }

  .datos-usuario-button-1 {
    font-family: "Roboto", sans-serif;
    border: 0;
    box-shadow: none;
    border-width: 1px;
    border-radius: 0;
    background-color: #c23023;
    border-color: #cc0000;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
    border: 1px solid transparent;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    padding: 1rem 2rem 1rem 2rem;
    margin-left: 15px;
    /* margin-right:25rem; */
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    cursor: pointer;
  }

  .datos-usuario-button-2 {
    font-family: "Roboto", sans-serif;
    border: 0;
    box-shadow: none;
    border-width: 1px;
    border-radius: 0;
    background-color: #a39e9d;
    border-color: #cc0000;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
    border: 1px solid transparent;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    margin-right: 15px;
    /* margin-left: 25rem; */
    padding: 1rem 2rem 1rem 2rem;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    cursor: pointer;
  }
  .datos-usuario-bottom-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media only screen and (min-width: 801px) and (max-width: 1300px) {
  .datos-usuario-box {
    display: flex;
    flex-direction: column;
    width: 95%;
  }

  .datos-usuario-text-box {
    display: flex;
    justify-content: flex-start;
    text-align: left;
    font-family: "Roboto", sans-serif;
    color: #333;
    font-size: 22px;
    line-height: 1.8;
    /* max-width: 80%; */
  }

  .datos-usuario-text {
    display: inline-flex;
    flex-direction: row;
    gap: 1rem;
  }
  .datos-usuario-text-1 {
    font-weight: 600;
    padding-left: 0.5rem;
  }
  .datos-usuario-text-2 {
    color: #777;
    font-weight: lighter;
  }

  .datos-usuario-form-1 {
    border-top: 3px solid #d2d6de;
    border-radius: 0.2rem;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  }
  .datos-usuario-form-2 {
    margin-top: 10px;
    padding-top: 5px;
    border-top: 3px solid #d2d6de;
    border-radius: 0.2rem;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  }
  .datos-usuario-text-box-1 {
    display: flex;
    justify-content: flex-start;
    align-items: left;
    text-align: left;
    font-family: "Roboto", sans-serif;
    color: #333;
    font-size: 20px;
    line-height: 1.8;
  }
  .datos-usuario-text-b {
    display: inline-flex;
    flex-direction: row;
    gap: 1rem;
  }
  .datos-usuario-text-3 {
    font-weight: 600;
    padding-left: 0.5rem;
  }

  .datos-usuario-icon-1 {
    padding-left: 1rem;
  }

  .datos-usuario-button-1 {
    font-family: "Roboto", sans-serif;
    border: 0;
    box-shadow: none;
    border-width: 1px;
    border-radius: 0;
    background-color: #c23023;
    border-color: #cc0000;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
    border: 1px solid transparent;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    padding: 1rem 2rem 1rem 2rem;
    margin-left: 15px;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    cursor: pointer;
  }

  .datos-usuario-button-2 {
    font-family: "Roboto", sans-serif;
    border: 0;
    box-shadow: none;
    border-width: 1px;
    border-radius: 0;
    background-color: #a39e9d;
    border-color: #cc0000;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
    border: 1px solid transparent;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    margin-right: 15px;
    /* margin-left: 25rem; */
    padding: 1rem 2rem 1rem 2rem;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    cursor: pointer;
  }
  .datos-usuario-bottom-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* display: inline-block; */
    flex-direction: row;
    width: 100%;
  }
}
@media only screen and (min-width: 771px) and (max-width: 800px) {
  .datos-usuario-box {
    display: flex;
    flex-direction: column;
  }

  .datos-usuario-text-box {
    display: flex;
    justify-content: flex-start;
    text-align: left;
    font-family: "Roboto", sans-serif;
    color: #333;
    font-size: 22px;
    line-height: 1.8;
  }

  .datos-usuario-text {
    display: inline-flex;
    flex-direction: row;
    gap: 1rem;
  }
  .datos-usuario-text-1 {
    font-weight: 600;
    padding-left: 0.5rem;
  }
  .datos-usuario-text-2 {
    color: #777;
    font-weight: lighter;
  }

  .datos-usuario-form-1 {
    border-top: 3px solid #d2d6de;
    border-radius: 0.2rem;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  }
  .datos-usuario-form-2 {
    margin-top: 10px;
    padding-top: 5px;
    border-top: 3px solid #d2d6de;
    border-radius: 0.2rem;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  }
  .datos-usuario-text-box-1 {
    display: flex;
    justify-content: flex-start;
    align-items: left;
    text-align: left;
    font-family: "Roboto", sans-serif;
    color: #333;
    font-size: 20px;
    line-height: 1.8;
  }
  .datos-usuario-text-b {
    display: inline-flex;
    flex-direction: row;
    gap: 1rem;
  }
  .datos-usuario-text-3 {
    font-weight: 600;
    padding-left: 0.5rem;
  }

  .datos-usuario-icon-1 {
    padding-left: 1rem;
  }

  .datos-usuario-button-1 {
    font-family: "Roboto", sans-serif;
    border: 0;
    box-shadow: none;
    border-width: 1px;
    border-radius: 0;
    background-color: #c23023;
    border-color: #cc0000;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
    border: 1px solid transparent;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    padding: 1rem 2rem 1rem 2rem;
    margin-left: 15px;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    cursor: pointer;
  }

  .datos-usuario-button-2 {
    font-family: "Roboto", sans-serif;
    border: 0;
    box-shadow: none;
    border-width: 1px;
    border-radius: 0;
    background-color: #a39e9d;
    border-color: #cc0000;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
    border: 1px solid transparent;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    margin-right: 15px;
    /* margin-left: 25rem; */
    padding: 1rem 2rem 1rem 2rem;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    cursor: pointer;
  }
  .datos-usuario-bottom-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* display: inline-block; */
    flex-direction: row;
    width: 100%;
  }
}
@media only screen and (min-width: 512px) and (max-width: 770px) {
  .datos-usuario-box {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .datos-usuario-text-box {
    display: flex;
    justify-content: flex-start;
    /*align-items: left;*/
    text-align: left;
    font-family: "Roboto", sans-serif;
    color: #333;
    font-size: 22px;
    line-height: 1.8;
    width: 98%;
  }

  .datos-usuario-text {
    display: inline-flex;
    flex-direction: row;
    gap: 1rem;
  }
  .datos-usuario-text-1 {
    font-weight: 600;
    padding-left: 0.5rem;
  }
  .datos-usuario-text-2 {
    color: #777;
    font-weight: lighter;
  }

  .datos-usuario-form-1 {
    border-top: 3px solid #d2d6de;
    border-radius: 0.2rem;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    width: 98%;
  }
  .datos-usuario-form-2 {
    margin-top: 10px;
    padding-top: 5px;
    border-top: 3px solid #d2d6de;
    border-radius: 0.2rem;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    /*max-width: 88%;*/
    width: 98%;
  }
  .datos-usuario-text-box-1 {
    display: flex;
    justify-content: flex-start;
    align-items: left;
    text-align: left;
    font-family: "Roboto", sans-serif;
    color: #333;
    font-size: 20px;
    line-height: 1.8;
  }
  .datos-usuario-text-b {
    display: inline-flex;
    flex-direction: row;
    gap: 1rem;
  }
  .datos-usuario-text-3 {
    font-weight: 600;
    padding-left: 0.5rem;
  }

  .datos-usuario-icon-1 {
    padding-left: 1rem;
  }

  .datos-usuario-button-1 {
    font-family: "Roboto", sans-serif;
    border: 0;
    box-shadow: none;
    border-width: 1px;
    border-radius: 0;
    background-color: #c23023;
    border-color: #cc0000;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
    border: 1px solid transparent;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    padding: 1rem 2rem 1rem 2rem;
    margin-left: 15px;
    /* margin-right:25rem; */
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    cursor: pointer;
  }

  .datos-usuario-button-2 {
    /* float: left; */
    font-family: "Roboto", sans-serif;
    border: 0;
    box-shadow: none;
    border-width: 1px;
    border-radius: 0;
    background-color: #a39e9d;
    border-color: #cc0000;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
    border: 1px solid transparent;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    margin-right: 15px;
    /* margin-left: 25rem; */
    padding: 1rem 2rem 1rem 2rem;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    cursor: pointer;
  }
  .datos-usuario-bottom-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* display: inline-block; */
    flex-direction: row;
    width: 100%;
  }
}

/* @media only screen and (min-width: 512px) and ( max-width:770px){
    .datos-usuario-box{
        width: 100%;
    }
    .datos-usuario-text-box {
        width: 98%;
    }
    .datos-usuario-form-1 {
        width: 98%;
    }
    .datos-usuario-form-2 {
        width: 98%;
    }
} */
@media only screen and (max-width: 512px) {
  .datos-usuario-box {
    display: flex;
    flex-direction: column;
    width: 100%;
    left: 0;
  }

  .datos-usuario-text-box {
    display: flex;
    justify-content: flex-start;
    align-items: left;
    text-align: left;
    flex-direction: column;

    font-family: "Roboto", sans-serif;
    color: #333;
    font-size: 22px;
    line-height: 1.8;
  }

  .datos-usuario-text {
    display: inline-flex;
    flex-direction: column;
    gap: 1rem;
  }
  .datos-usuario-text-1 {
    font-weight: 600;
    padding-left: 0.5rem;
  }
  .datos-usuario-text-2 {
    color: #777;
    font-weight: lighter;
  }

  .datos-usuario-form-1 {
    border-top: 3px solid #d2d6de;
    border-radius: 0.2rem;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  }
  .datos-usuario-form-2 {
    border-top: 3px solid #d2d6de;
    border-radius: 0.2rem;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  }
  .datos-usuario-text-box-1 {
    display: flex;
    justify-content: flex-start;
    align-items: left;
    text-align: left;
    flex-direction: row;
    font-family: "Roboto", sans-serif;
    color: #333;
    font-size: 20px;
    line-height: 1.8;
  }
  .datos-usuario-text-b {
    display: inline-flex;
    flex-direction: column;
    gap: 1rem;
  }
  .datos-usuario-text-3 {
    font-weight: 600;
    padding-left: 0.5rem;
  }

  .datos-usuario-icon-1 {
    padding-left: 1rem;
  }

  .datos-usuario-button-1 {
    display: flex;
    font-family: "Roboto", sans-serif;
    border: 0;
    box-shadow: none;
    border-width: 1px;
    border-radius: 0;
    background-color: #c23023;
    border-color: #cc0000;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
    border: 1px solid transparent;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    cursor: pointer;
    width: 50%;
    margin-right: 0px;
  }

  .datos-usuario-button-2 {
    display: flex;

    font-family: "Roboto", sans-serif;
    border: 0;
    box-shadow: none;
    border-width: 1px;
    border-radius: 0;
    background-color: #a39e9d;
    border-color: #cc0000;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
    border: 1px solid transparent;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    cursor: pointer;
    width: 50%;
    margin-left: 0px;
  }
  .datos-usuario-bottom-buttons {
    display: inline-flex;
    flex-direction: row;
    width: 100%;
  }
}
