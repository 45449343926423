@media only screen and (min-width: 512px) {

    .user-form-box{
        display:flex;
        flex-direction: column;
        padding:1rem;
        gap: 0.5rem;
    }
    .user-form-row-1{
        display:flex;
        flex-direction: row;
        gap:2rem;
    }
    .location-top-inputs-1 {
        width: 30%
    }
    .location-top-input-2{
        width:55%
    }
    .user-form-row-2{
        display:flex;
        flex-direction: row;
        gap:2rem;
    }
    .select-box{
        width: 100%;
        color: #bbb;
        height: 34px;
        opacity: 1;
        font-size: 14px;
        line-height: 1.42857143;
        color: #333;
        background-color: #fff;
        border: 1px solid #ccc;
        transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;

    }
    .select-form-box{
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        line-height: 1.42857143;
        color: #666;
        width:100%;

    }

    .select-column-1{
        position: relative;
        display:flex; /* added*/
        flex-direction:column; /* added*/
    }
    .select-row-1{
        display: flex;
        /* justify-content: space-between; */
        flex-direction: column;
    }
    .select-label-1{
        display:inline-flex;
        font-size: 18px;
        color:#333;
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
    }
}
@media only screen and (max-width: 512px) {
    .user-form-box{
        display:flex;
        flex-direction: column;
        padding:1rem;
        gap: 0.5rem;
    }
    .user-form-row-1{
        display:flex;
        flex-direction: column;
        gap:2rem;
    }
    
    .user-form-row-2{
        display:flex;
        flex-direction: column;
        gap:2rem;
    }
    .select-box{
        width: 100%;
        color: #bbb;
        height: 34px;
        opacity: 1;
        font-size: 14px;
        line-height: 1.42857143;
        color: #333;
        background-color: #fff;
        border: 1px solid #ccc;
        transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    
    }
    .select-form-box{
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        line-height: 1.42857143;
        color: #666;
        width:100%;
    
    }
    
    .select-column-1{
        position: relative;
        display:flex; /* added*/
        flex-direction:column; /* added*/
    }
    .select-row-1{
        display: flex;
        /* justify-content: space-between; */
        flex-direction: column;
    }
    .select-label-1{
        display:inline-flex;
        font-size: 18px;
        color:#333;
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
    }
    
}