.input-5-form-box{
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    line-height: 1.42857143;
    color: #666;
    width:100%;

}

.input-5-column-1{
    position: relative;
    display:flex; /* added*/
    flex-direction:column; /* added*/
}
.input-5-row-1{
    display: flex;
    justify-content: space-between;
}
.input-5-label-1{
    display:inline-flex;
    font-size: 18px;
    color:#333;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
}

.input-5-input-1{
}

.input-5-box{

    color: #bbb;
    height: 34px;
    opacity: 1;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
    background-color: #fff;
    border: 1px solid #ccc;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;

}

.input-5-acordeon{
}

.input-5-box:focus {
      outline: none !important;
    border:1px solid #c23023;  }

.input-5-mandatory-text{
    padding-top:0.7rem;
    color: #a94442;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 18px;
}
.input-5-mandatory-disabled{
    display: none;
}

.input-5-regex-text{
    padding-top:0.7rem;
    color: #a94442;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 18px;
}
.input-5-regex-disabled{
    display: none;
}