@media only screen and (min-width: 812px) {
    .button-list-box{
        list-style-type: none;
        width: 15rem;
    }.button{
        background-color: rgb(244, 244, 244);
        background-image: none;
        border-bottom-color: rgb(221, 221, 221);
        border-width: 0.1rem;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        border-style: solid;
        border-image-outset: 0;
        border-image-repeat: stretch;
        border-image-slice: 100%;
        border-image-source: none;
        border-left-color: rgb(221, 221, 221);
        border-left-style: solid;
        border-right-color: rgb(221, 221, 221);
        border-right-style: solid;
        border-top-color: rgb(221, 221, 221);
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        border-top-style: solid;
        box-shadow: none;
        box-sizing: border-box;
        color: rgb(68, 68, 68);
        cursor: pointer;
        display: block;
        font-family: "Roboto", sans-serif;
        font-size: 18px;
        margin-top:2rem;
        width:50rem;
        padding-top:0.7rem;
        height: 2.5rem;
        white-space: nowrap;
        text-align: center;
        text-decoration-color: rgb(68, 68, 68)
    }.button:hover {
        color: rgb(60, 60, 60);
        text-decoration-color: rgb(60, 60, 60);
        background-color: rgb(220, 220, 220);
        border-bottom-color: rgb(200, 200, 200);
        border-width: 0.1rem;
    }
    .cambiarCita-form{
        display:flex;
        flex-direction: column;
        width:100%;
    }
    .cambiarCita-row-1{
        display:flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: left;
        text-align: left;
        font-family:'Roboto', sans-serif;
        color: #333;
        font-size:22px;
        line-height: 1.8;
    }.cambiarCita-text-1{
        font-weight: 600;
        padding-left:0.5rem;
    }
    .cambiarCita-text-2{
        color: #777;
        font-weight:lighter;
        padding-left: 1rem;
    }
}
@media only screen and (max-width: 812px) {
    .button-list-box{
        list-style-type: none;
        width: 100%;
        padding-left:0px;
        margin-left: 0px;

    }.button{
        background-color: rgb(244, 244, 244);
        background-image: none;
        border-bottom-color: rgb(221, 221, 221);
        border-width: 0.1%;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        border-style: solid;
        border-image-outset: 0;
        border-image-repeat: stretch;
        border-image-slice: 100%;
        border-image-source: none;
        border-left-color: rgb(221, 221, 221);
        border-left-style: solid;
        border-right-color: rgb(221, 221, 221);
        border-right-style: solid;
        border-top-color: rgb(221, 221, 221);
        border-top-style: solid;
        box-shadow: none;
        box-sizing: border-box;
        color: rgb(68, 68, 68);
        cursor: pointer;
        font-family: "Roboto", sans-serif;
        font-size: 18px;
        margin-top:2rem;
        width:100%;
        padding-top:0.7rem;
        height: 2.5rem;
        white-space: nowrap;
        text-align: center;
        text-decoration-color: rgb(68, 68, 68)
    }.button:hover {
        color: rgb(60, 60, 60);
        text-decoration-color: rgb(60, 60, 60);
        background-color: rgb(220, 220, 220);
        border-bottom-color: rgb(200, 200, 200);
        border-width: 0.1rem;
    }
    .cambiarCita-form{
        display:flex;
        flex-direction: column;
        width:100%;
    }
    .cambiarCita-row-1{
        display:flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: left;
        text-align: left;
        font-family:'Roboto', sans-serif;
        color: #333;
        font-size:22px;
        line-height: 1.8;
    }.cambiarCita-text-1{
        font-weight: 600;
    }
    .cambiarCita-text-2{
        color: #777;
        font-weight:lighter;
    }
}