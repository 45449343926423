@media only screen and (min-width: 1100px) {

    .confirmarCita-form{
        display:flex;
        flex-direction: column;
    }
    .confirmarCita-row-1{
        display:flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: left;
        text-align: left;
        font-family:'Roboto', sans-serif;
        color: #333;
        font-size:22px;
        line-height: 1.8;
    }.confirmarCita-text-1{
        font-weight: 600;
        padding-left:0.5rem;
    }
    .confirmarCita-text-2{
        color: #777;
        font-weight:lighter;
        padding-left: 1rem;
    }.confirmarCita-row-3{
        display: flex;
        gap:1rem;
        padding-top: 4rem;

    }.confirmarCita-row-2{
        display: flex;
        /* flex-direction: column; */
        gap:1rem;border-top: 3px solid #d2d6de;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
        height : 25rem;
    }
    .confirmarCita-row-2-list{
        position: relative;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;float: left;
        margin-top: 10px;
        margin-bottom: 10px;
        display: block;
        margin-block-start: 1.33em;
        margin-block-end: 1.33em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        box-sizing: border-box;
        font-size: 18px;
    }
    .confirmarCita-row-2-info{
        position: relative;
        margin-left: 7px;
    }
    .confirmarCita-row-2-header-box{
        display:flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: left;
        text-align: left;
        border-bottom: 1px solid #f4f4f4;
        display: table; 
        text-align: left;
        max-width: 50%;
    }
    .leaflet-container {
        width: 100%;
        height: 99%;
    }
    .confirmarCita-row-2-header-text{
    padding-left: 1rem;
    }
    .confirmarCita-col-2-info{
        position: relative;
        padding-left: 10px;
    }
    .confirmarCita-button-1{
        position: relative;
        background-color: #c23023;
        border-color: #cc0000;
    }
    .map-div{
        width: 100%;
        height: 85%; 
        padding-Left:3rem;
    }
    .map-box{
        width: 100%;
        min-height: 90%;
        padding-Left:3rem
    }
    .confirmarCita-input-box{
        color: #bbb;
        height: 34px;
        opacity: 1;
        font-size: 14px;
        line-height: 1.42857143;
        color: #333;
        margin:1rem;
        background-color: #fff;
        border: 1px solid #ccc;
        transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    
    }
    
    .confirmarCita-input-acordeon{
    }
    
    .confirmarCita-input-box:focus {
          outline: none !important;
        border:1px solid #c23023;  
    }
}
@media only screen and (max-width: 1100px) {

    .confirmarCita-form{
        display:flex;
        flex-direction: column;
    }
    .confirmarCita-row-1{
        display:flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        text-align: center;
        font-family:'Roboto', sans-serif;
        color: #333;
        font-size:22px;
        line-height: 1.8;
    }.confirmarCita-text-1{
        font-weight: 600;
        padding-left:0.5rem;
    }
    .confirmarCita-text-2{
        color: #777;
        font-weight:lighter;
        padding-left: 1rem;
    }.confirmarCita-row-3{
        display: flex;
        flex-direction: column;
        gap:1rem;
        padding-top: 20rem;

    }.confirmarCita-row-2{
        display: flex;
        flex-direction: column;

        gap:1rem;border-top: 3px solid #d2d6de;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
        height : 35rem;
        width:80%;
        padding-left: 10%;
        padding-right: 10%;
    }
    .confirmarCita-row-2-list{
        position: relative;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;float: left;
        margin-top: 10px;
        margin-bottom: 10px;
        display: block;
        margin-block-start: 1.33em;
        margin-block-end: 1.33em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        box-sizing: border-box;
        font-size: 18px;
    }
    .confirmarCita-row-2-info{
        position: relative;
        margin-left: 7px;
    }
    .confirmarCita-row-2-header-box{
        display:flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        text-align: center;
        border-bottom: 1px solid #f4f4f4;
        display: table; 
        text-align: center;
    }
    .leaflet-container {
        width: 100%;
        height: 99%;
        align-items: left;
    }
    .confirmarCita-row-2-header-text{
    padding-left: 1rem;
    }
    .confirmarCita-col-2-info{
        position: relative;
        padding-left: 10px;
    }
    .confirmarCita-button-1{
        position: relative;
        background-color: #c23023;
        border-color: #cc0000;
    }
    .map-div{
        width: 100%;
        height: 95%; 
        paddingLeft:3rem
    }
    .map-box{
        width: 100%;
        /* height: 95%;  */
        height: 500px;
        paddingLeft:3rem
    }
    .confirmarCita-input-box{
        color: #bbb;
        height: 34px;
        opacity: 1;
        font-size: 14px;
        line-height: 1.42857143;
        color: #333;
        margin:0.8rem;
        background-color: #fff;
        border: 1px solid #ccc;
        transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    
    }
    
    .confirmarCita-input-acordeon{
    }
    
    .confirmarCita-input-box:focus {
          outline: none !important;
        border:1px solid #c23023;  
    }
}