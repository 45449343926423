.input-7-form-box{
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    line-height: 1.42857143;
    color: #666;
    width:100%;

}

.input-7-column-1{
    position: relative;
    display:flex; /* added*/
    flex-direction:column; /* added*/
}
.input-7-row-1{
    display: flex;
    justify-content: space-between;
}
.input-7-label-1{
    display:inline-flex;
    font-size: 18px;
    color:#333;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
}

.input-7-input-1{
}

.input-7-box{

    color: #bbb;
    height: 34px;
    opacity: 1;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
    background-color: #fff;
    border: 1px solid #ccc;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;

}

.input-7-acordeon{
}

.input-7-box:focus {
      outline: none !important;
    border:1px solid #c23023;  }

.input-7-mandatory-text{
    padding-top:0.7rem;
    color: #a94442;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 18px;
}
.input-7-mandatory-disabled{
    display: none;
}

.input-7-regex-text{
    padding-top:0.7rem;
    color: #a94442;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 18px;
}
.input-7-regex-disabled{
    display: none;
}

/*
.odio{
    color: "#bbb",
    width:"100%",
    border:"0",
   // overflow: "auto",

    opacity: 1,
    lineHeight: 1.42857143,
    fontSize: "18px",
    color:"#333",
    fontFamily: "Roboto, sans-serif",
    fontWeight: 700,
    color: "#333",
    backgroundColor: "#fff",
    transition: "border-color ease-in-out .15s,box-shadow ease-in-out .15s",
    '&:focus': {
         outline: 'none !important',
            border:'1px solid #c23023 !important',
            borderColor: '#c23023',
      },
    '& css-154xyx0-MuiInputBase-root-MuiOutLinedInput-root.Mui-focused .MuiOutLinedInput-notchedOutline': {
        borderColor:'red',
    }
}
*/

.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
    border-color: #c23023 !important;
    border-width: 1px !important;
}