@media only screen and (min-width:1300px){
    .wizard-box{
        padding-top:1rem;
        text-align: left;
        display:flex;
        flex-direction: column;
        gap:1rem;
        width:70rem;
        /* width:95%; */
    }

    .bottomButton-box{
        width:100%;
        /* display: inline-block; */
    }

    .bottomButtons-right{
        /* float: right; */
        /* justify-content: flex-end; */
        width: 10%;
        margin-left: 55rem;
        padding: 5px 14px;
        background-color: #e23627;
        border: 1px solid #ddd;
        border-radius: 12px;
        color: #FFF;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 18px;
        cursor:pointer;
    }
    .bottomButtons-left{
        width:10%;
        margin-left: 1rem;
        padding: 5px 14px;
        background-color: #e23627;
        border: 1px solid #ddd;
        border-radius: 12px;
        color: #FFF;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 18px;
        cursor:pointer;

    }
    .selected-tab-button{
        border-top-color: #dd4b39;
        border-bottom: 0px solid transparent;  
        border-right: 0px solid transparent;  
        border-left: 0px solid transparent;  
        background-color: #c23023;
        color: #fff;
        font-weight: 600;
        border-radius: 0;
        border-top: 3px solid transparent;
        padding: 10px 15px;
        font-family: 'Roboto', sans-serif !important;
        font-size: 18px;
        left : 5 rem;

    }.non-selected-tab-button{
        color: #444;border-radius: 0;
        border-top: 0px solid transparent;  
        border-bottom: 0px solid transparent;  
        border-right: 0px solid transparent;  
        border-left: 0px solid transparent;  
        padding: 10px 15px;
        background-color: transparent;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 18px;
    }
    .text-title{
        font-weight: 500;
        line-height: 1.1;

        font-family: 'Roboto', sans-serif;
        font-size: 30px;
    
    }
    .wizard-header-title-box{
        margin-bottom:1rem;
    }
    .text-desc{
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 18px;
        line-height: 1.42857143;
        color: #333;
        margin: 0 0 10px;
    }

    .wizard-form-1{
        width:100%;
        height: 100%;
        border-top: 3px solid #d2d6de;
        border-radius: 0.2rem;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    }

    .wizard-buttons-row-1{
        width:100%;
    }

    .buttomButtons-left-disabled{
        /* width:10%; */
        margin-left: 1rem;
        padding: 5px 14px;
        background-color: rgb(255, 255, 255);
        border: 1px solid #ddd;
        border-radius: 12px;
        color: #777;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 18px;

    }
    .wizard-header-desc-box{
        margin-bottom:1rem;
    }
    .wizard-header-buttons{
        
        align-items: left;  
        margin-top:1rem;
    }
    .wizard-header-progress-bar{
        
        align-items: left;  
        margin-top:1rem;
    }
}
@media only screen and (min-width: 512px) and ( max-width:1300px){
    .wizard-box{
        padding-top:1rem;
        text-align: left;
        display:flex;
        flex-direction: column;
        gap:1rem;
        /* width:70rem; */
        width:95%;
    }

    .bottomButton-box{
        width:100%;
        display: inline-block;
    }

    .bottomButtons-right{
        float: right;
        justify-content: flex-end;
        /* width: 10%; */
        /*margin-left: 55rem;*/
        padding: 5px 14px;
        background-color: #e23627;
        border: 1px solid #ddd;
        border-radius: 12px;
        color: #FFF;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 18px;
        cursor:pointer;
    }
    .bottomButtons-left{
        /*width:10%;*/
        margin-left: 1rem;
        padding: 5px 14px;
        background-color: #e23627;
        border: 1px solid #ddd;
        border-radius: 12px;
        color: #FFF;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 18px;
        cursor:pointer;

    }
    .selected-tab-button{
        border-top-color: #dd4b39;
        border-bottom: 0px solid transparent;  
        border-right: 0px solid transparent;  
        border-left: 0px solid transparent;  
        background-color: #c23023;
        color: #fff;
        font-weight: 600;
        border-radius: 0;
        border-top: 3px solid transparent;
        padding: 10px 15px;
        font-family: 'Roboto', sans-serif !important;
        font-size: 18px;
        left : 5 rem;

    }.non-selected-tab-button{
        color: #444;border-radius: 0;
        border-top: 0px solid transparent;  
        border-bottom: 0px solid transparent;  
        border-right: 0px solid transparent;  
        border-left: 0px solid transparent;  
        padding: 10px 15px;
        background-color: transparent;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 18px;
    }
    .text-title{
        font-weight: 500;
        line-height: 1.1;

        font-family: 'Roboto', sans-serif;
        font-size: 30px;
    
    }
    .wizard-header-title-box{
        margin-bottom:1rem;
    }
    .text-desc{
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 18px;
        line-height: 1.42857143;
        color: #333;
        margin: 0 0 10px;
    }

    .wizard-form-1{
        width:100%;
        height: 100%;
        border-top: 3px solid #d2d6de;
        border-radius: 0.2rem;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    }

    .wizard-buttons-row-1{
        width:100%;
    }

    .buttomButtons-left-disabled{
        /* width:10%; */
        margin-left: 1rem;
        padding: 5px 14px;
        background-color: rgb(255, 255, 255);
        border: 1px solid #ddd;
        border-radius: 12px;
        color: #777;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 18px;

    }
    .wizard-header-desc-box{
        margin-bottom:1rem;
    }
    .wizard-header-buttons{
        
        align-items: left;  
        margin-top:1rem;
    }
    .wizard-header-progress-bar{
        
        align-items: left;  
        margin-top:1rem;
    }
}
/* @media only screen and (min-width: 512px) {
    .wizard-box{
        padding-top:1rem;
        text-align: left;
        display:flex;
        flex-direction: column;
        gap:1rem;
        width:70rem;
    }

    .bottomButtons-box{
        width:100%;
    }

    .bottomButtons-right{
        width: 10%;
        margin-left: 55rem;
        padding: 5px 14px;
        background-color: #e23627;
        border: 1px solid #ddd;
        border-radius: 12px;
        color: #FFF;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 18px;
        cursor:pointer;
    }
    .bottomButtons-left{
        width:10%;
        margin-left: 1rem;
        padding: 5px 14px;
        background-color: #e23627;
        border: 1px solid #ddd;
        border-radius: 12px;
        color: #FFF;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 18px;
        cursor:pointer;

    }
    .selected-tab-button{
        border-top-color: #dd4b39;
        border-bottom: 0px solid transparent;  
        border-right: 0px solid transparent;  
        border-left: 0px solid transparent;  
        background-color: #c23023;
        color: #fff;
        font-weight: 600;
        border-radius: 0;
        border-top: 3px solid transparent;
        padding: 10px 15px;
        font-family: 'Roboto', sans-serif !important;
        font-size: 18px;
        left : 5 rem;

    }.non-selected-tab-button{
        color: #444;border-radius: 0;
        border-top: 0px solid transparent;  
        border-bottom: 0px solid transparent;  
        border-right: 0px solid transparent;  
        border-left: 0px solid transparent;  
        padding: 10px 15px;
        background-color: transparent;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 18px;
    }
    .text-title{
        font-weight: 500;
        line-height: 1.1;

        font-family: 'Roboto', sans-serif;
        font-size: 30px;
    
    }
    .wizard-header-title-box{
        margin-bottom:1rem;
    }
    .text-desc{
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 18px;
        line-height: 1.42857143;
        color: #333;
        margin: 0 0 10px;
    }

    .wizard-form-1{
        width:100%;
        height: 100%;
        border-top: 3px solid #d2d6de;
        border-radius: 0.2rem;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    }

    .wizard-buttons-row-1{
        width:100%;
    }

    .buttomButtons-left-disabled{
        width:10%;
        margin-left: 1rem;
        padding: 5px 14px;
        background-color: rgb(255, 255, 255);
        border: 1px solid #ddd;
        border-radius: 12px;
        color: #777;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 18px;

    }
    .wizard-header-desc-box{
        margin-bottom:1rem;
    }
    .wizard-header-buttons{
        
        align-items: left;  
        margin-top:1rem;
    }
    .wizard-header-progress-bar{
        
        align-items: left;  
        margin-top:1rem;
    }
} */
@media only screen and (max-width: 512px) {
    .wizard-box{
        padding-top:1rem;
        text-align: left;
        display:flex;
        flex-direction: column;
        gap:1rem;
        width:100%;
    }
    
    .bottomButtons-box{
        width:100%;
    }
    
    .bottomButtons-right{
        width: 50%;
        margin-left: 0rem;
        padding: 5px 12px;
        background-color: #e23627;
        border: 1px solid #ddd;
        border-radius: 12px;
        color: #FFF;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 15px;
        cursor:pointer;
    }
    .bottomButtons-left{
        width:50%;
        padding: 5px 14px;
        background-color: #e23627;
        border: 1px solid #ddd;
        border-radius: 12px;
        color: #FFF;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 15px;
        cursor:pointer;
    
    }
    .buttomButtons-left-disabled{
        width:50%;
        padding: 5px 14px;
        background-color: rgb(255, 255, 255);
        border: 1px solid #ddd;
        border-radius: 12px;
        color: #777;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 15px;
    
    }
    .selected-tab-button{
        border-top-color: #dd4b39;
        border-bottom: 0px solid transparent;  
        border-right: 0px solid transparent;  
        border-left: 0px solid transparent;  
        background-color: #c23023;
        color: #fff;
        font-weight: 600;
        border-radius: 0;
        border-top: 3px solid transparent;
        padding: 10px 15px;
        font-family: 'Roboto', sans-serif !important;
        font-size: 15px;
        left : 1 rem;
    
    }.non-selected-tab-button{
        color: #444;border-radius: 0;
        border-top: 0px solid transparent;  
        border-bottom: 0px solid transparent;  
        border-right: 0px solid transparent;  
        border-left: 0px solid transparent;  
        padding: 10px 15px;
        background-color: transparent;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 15px;
    }
    .text-title{
        font-weight: 500;
        line-height: 1.1;
    
        font-family: 'Roboto', sans-serif;
        font-size: 20px;
       
    }
    .wizard-header-title-box{
        margin-bottom:1rem;
    }
    .text-desc{
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 15px;
        line-height: 1.42857143;
        color: #333;
        margin: 0 0 10px;
    }
    
    .wizard-form-1{
        width:100%;
        height: 100%;
        border-top: 3px solid #d2d6de;
        border-radius: 0.2rem;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    }
    
    .wizard-buttons-row-1{
        width:100%;
    }
    

    .wizard-header-desc-box{
        margin-bottom:1rem;
    }
    .wizard-header-buttons{
         
        align-items: left;  
        margin-top:1rem;
    }
    .wizard-header-progress-bar{
         
        align-items: left;  
        margin-top:1rem;
    }
    
}
