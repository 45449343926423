@media only screen and (min-width: 512px) {

    .centered {
        padding: 3rem 0rem 0rem 0rem;
    }
    .footerbox {
        display: flex;
        bottom: 0px;
        width: 100%;
        height : 100%;
    }


    .footerbox-left-box{
        background-color: #c23023;
        width: 31.5%;
    }

    .footerbox-left-text{
        color: white;
        left: 230px;
    }
    .footerbox-right-box{
        display: inline-flex;
        background-color: #f6f6f6;
        width: 68.5%;
        display:flex;
        flex-direction: column;
        
        
    }
    .footer-text-box {
        display: flex;
        flex-direction:column;
    }
    .footerbox-right-text-1{
        font-family:'Roboto', sans-serif;
        display: flex;
        font-weight: 500;
        padding: 3%;
        font-size: 18px;
        line-height: 1.42857143;
    }

    .footer-right-text-a-1{
        color: #c23023;
        text-decoration: none;
        background-color: transparent;
        padding: 0px 10px 0px 10px;
        font-weight: 500;
        box-sizing: border-box;
    }
    .footer-right-text-a-2{
        color: #c23023;
        text-decoration: none;
        background-color: transparent;
        padding: 0px 0px 0px 10px;
        font-weight: 500;
        box-sizing: border-box;
    }
    a:hover, a:active {
    color: red;
    }
    .footer-right-text-li-2{
        float: left;
        border-left: 1px solid #9d9e9f;
        color: transparent

    }
    .footer-content{
        display: flex;
        justify-content: space-between;
    }
    .footer-right-text-2{
        display: flex;
        flex-direction: column;
        align-content: flex-start;
        text-align: left;
    }
    .footer-right-text-strong-2{
        font-weight: 500;
        padding: 0px 0px 0px 45px;
        font-size: 18px;
        line-height: 1.42857143;

    }
    .footer-right-text-strong-1{
        font-weight: 500;
        padding: 0px 0px 0px 45px;
        font-size: 18px;
        line-height: 1.42857143;
    }

    .iconImagen-1{
        right: 12px;
        height:auto;
        padding-right: 5%;
    }

    .img-responsive-1{

        height:auto;
    }
    .logo-footer{
        float:right;
        margin-right: 10px;
        bottom:0px;
        height: 100px;
        margin-bottom: 0.1%;
        margin-top: 10%;
    }
   
}
@media only screen and (max-width: 512px) {

    .centered {
        padding: 0rem 0rem 0rem 0rem;
    }
    .footerbox {
        display: flex;
        bottom: 0px;
        width: 100%;
        max-height: 100px;
        height : 100%;
    }


    .footerbox-left-box{
        width: 0;
    }

    .footerbox-left-text{
        color: white;
        left: 230px;


    }
    .footerbox-right-box{
        background-color: #f6f6f6;
        width: 100%;
        display:flex;
        padding: 2%;
    }
    .footer-text-box {
        display: flex;
        flex-direction:column;
    }
    .footerbox-right-text-1{
        font-family:'Roboto', sans-serif;
        display: inline-flex;
        font-weight: 500;
        font-size: 10px;
        line-height: 1.42857143;
        width: 100%;
    }
    .footer-content{
        display: flex;
    }
    .footer-right-text-a-1{
        color: #c23023;
        text-decoration: none;
        background-color: transparent;
        padding: 0px 10px 0px 0px;
        font-weight: 500;
        box-sizing: border-box;
    }
    .footer-right-text-a-2{
        color: #c23023;
        text-decoration: none;
        background-color: transparent;
        padding: 0px 0px 0px 0px;
        font-weight: 500;
        box-sizing: border-box;
        /* width:100%; */
    }
    a:hover, a:active {
    color: red;
    }
    .footer-right-text-li-2{
        float: left;
        border-left: 1px solid #9d9e9f;
        color: transparent;
    }
    .footer-right-text-2{
        display:block;
        flex-direction: column;
        text-align: left;
        width:100%
    }
    .footer-right-text-strong-2{
        font-weight: 500;
        padding: 0px 0px 0px 2px;
        font-size: 12px;
        line-height: 1.42857143;
        width:100%

    }
    .footer-right-text-strong-1{
        font-weight: 500;
        padding: 0px 0px 0px 10px;
        font-size: 12px;
        line-height: 1.42857143;
    }

    .iconImagen-1{
        right: 12px;
        height:auto;
    }

    .img-responsive-1{

        height:auto;
    }
    .logo-footer{
        float:right;
        margin-right: 10px;
        bottom:0px;
        height: 100px;
        margin-bottom: 0.1%;
        margin-top: 10%;
        visibility: hidden;
    }
    
}
