@media only screen and (min-width: 1300px) {
    .pedirCita-form{
        display:flex;
        flex-direction: column;
        max-width: 70%;

    }
    .pedirCita-row-1{
        display:flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: left;
        text-align: left;
        font-family:'Roboto', sans-serif;
        color: #333;
        font-size:22px;
        line-height: 1.8;
    }.pedirCita-text-1{
        font-weight: 600;
        padding-left:0.5rem;
    }
    .pedirCita-text-2{
        color: #777;
        font-weight:lighter;
        padding-left: 1rem;
    }.pedirCita-row-3{
        display: flex;
        gap:1rem;
        padding-top: 4rem;

    }.pedirCita-row-2{
        display: flex;
        /* flex-direction: column; */
        gap:1rem;border-top: 3px solid #d2d6de;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
        /* height : 25rem; */
    }
    .pedirCita-row-2-list{
        position: relative;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;float: left;
        margin-top: 10px;
        margin-bottom: 10px;
        display: block;
        margin-block-start: 1.33em;
        margin-block-end: 1.33em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        box-sizing: border-box;
        font-size: 18px;
    }
    .pedirCita-row-2-info{
        position: relative;
        margin-left: 7px;
    }
    .pedirCita-row-2-header-box{
        display:flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: left;
        text-align: left;
        border-bottom: 1px solid #f4f4f4;
        display: table; 
        text-align: left;
    }
    .leaflet-container {
        width: 100%;
        height: 99%;
    }
    .pedirCita-row-2-header-text{
    padding-left: 1rem;
    }
    .pedirCita-col-2-info{
        position: relative;
        padding-left: 10px;
    }
    .map-box{
        width: 340px; 
        height: 200px;
        padding-left:3rem;
        
   }
   .col6-box{
    height:"30rem";
   }
}
@media only screen and (min-width: 512px) and ( max-width:1300px) {
    .pedirCita-form{
        display:flex;
        flex-direction: column;
        /* overflow: scroll; */
    }
    .pedirCita-row-1{
        display:flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: left;
        text-align: left;
        font-family:'Roboto', sans-serif;
        color: #333;
        font-size:22px;
        line-height: 1.8;
    }.pedirCita-text-1{
        font-weight: 600;
        padding-left:0.5rem;
    }
    .pedirCita-text-2{
        color: #777;
        font-weight:lighter;
        padding-left: 1rem;

    }.pedirCita-row-3{
        display: flex;
        flex-direction: column; 
        align-items: left;
        text-align: left;
        /* padding-top: 405px; */
        /* overflow: hidden; */
        /* overflow: scroll; */

    }.pedirCita-row-2{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: center; 
        margin-left:0.5rem;
        border-top: 3px solid #d2d6de;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
        /* height : 30rem; */
    }
    .pedirCita-row-2-list{
        position: relative;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
        margin-top: 10px;
        margin-bottom: 10px;
        display: block;
        margin-block-start: 1.33em;
        margin-block-end: 1.33em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        box-sizing: border-box;
        font-size: 18px;
    }
    .pedirCita-row-2-info{
        position: relative;
        margin-left: 7px;
    }
    .pedirCita-row-2-header-box{
        display:flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: left;
        text-align: left;
        border-bottom: 1px solid #f4f4f4;
        display: table; 
        text-align: left;
        height: 100%;
    }
    .leaflet-container {
        width: 100%;
        height: 99%;
    }
    .pedirCita-row-2-header-text{
        padding-left: 1rem;
    }
    .pedirCita-col-2-info{
        position: relative;
        padding-left: 10px;
    }
    .map-box{
        width: 100%; 
        height:350px;
        padding-left:1rem;
        /* display: none; */
        /* overflow: scroll; */
   }
   .col6-box{
    height:"30rem";
    width:"80%"
   }
}
.pedirCita-radioButtons{
    display:flex;
    flex-direction: column;
}
@media only screen and (max-width: 512px) {
    .pedirCita-form{
        display:flex;
        flex-direction: column;
    }
    .pedirCita-row-1{
        display:flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: left;
        text-align: left;
        font-family:'Roboto', sans-serif;
        color: #333;
        font-size:22px;
        line-height: 1.8;
    }.pedirCita-text-1{
        font-weight: 600;
        padding-left:0.5rem;
    }
    .pedirCita-text-2{
        color: #777;
        font-weight:lighter;
        padding-left: 1rem;
    }.pedirCita-row-3{
        display: flex;
        flex-direction: column; 
        align-items: left;
        text-align: left;
        /* padding-top: 405px; */

    }.pedirCita-row-2{
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center; 
        margin-left:0.5rem;
        border-top: 3px solid #d2d6de;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
        /* height : 30rem; */
    }
    .pedirCita-row-2-list{
        position: relative;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
        margin-top: 10px;
        margin-bottom: 10px;
        display: block;
        margin-block-start: 1.33em;
        margin-block-end: 1.33em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        box-sizing: border-box;
        font-size: 18px;
    }
    .pedirCita-row-2-info{
        position: relative;
        margin-left: 7px;
    }
    .pedirCita-row-2-header-box{
        display:flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: left;
        text-align: left;
        border-bottom: 1px solid #f4f4f4;
        display: table; 
        text-align: left;
        height: 100%;
    }
    .leaflet-container {
        width: 100%;
        height: 99%;
    }
    .pedirCita-row-2-header-text{
        padding-left: 1rem;
    }
    .pedirCita-col-2-info{
        position: relative;
        padding-left: 10px;
    }
    .map-box{
        width: 100%; 
        height:350px;
        padding-left:1rem;
        /* overflow: scroll; */
   }
   .col6-box{
    height:"30rem";
    width:"80%"
   }
}
.pedirCita-radioButtons{
    display:flex;
    flex-direction: column;
}