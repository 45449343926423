@media only screen and (min-width: 512px) {

    .centered {
        padding: 3rem 0rem 0rem 0rem;
        margin: auto;
    }
    .login-box{
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        max-width: 32rem;

    }
    .login-text-1{
        padding: 0rem 0rem 0.8rem 0rem;
    }
    .login-text-2{
        padding: 0;
        margin: 0;
        display: flex;

    }
    .login-title{
        font-size: 35px;
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        color: #444;
    }
    .login-title-2{
        font-size: 35px;
        font-family: 'Roboto', sans-serif;
        font-weight: 300;
        color: #444;
    }
    .login-text{
        padding: 0 1rem;
        color: #666;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 18px;
        text-align: center;
    }

    .login-button{
        font-family: 'Roboto', sans-serif;
        box-sizing: border-box;
        border: 0;
        box-shadow: none;
        border-width: 1px;
        border-radius: 0;
        background-color: #c23023;
        border-color: #cc0000;
        margin-top: 15px !important;
        margin-bottom: 15px !important;
        border: 1px solid transparent;
        display: block;
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.42857143;
        text-align: center;
        white-space: nowrap;
        width:100%;
        padding: 6px 12px;
        transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
        cursor:pointer;
    }

    .login-form-text-and-icon{
        display:flex;
    }

    .disabled{
        display: none;
    }
    .login-input-boxes{
        padding-top:1rem;

        width:30rem;
    }
}
@media only screen and (max-width: 512px) {
    .container header .header-nav-area #nav_container  {
      display:flex;
    }
    .centered {
        padding: 3rem 0rem 0rem 0rem;
    }
    .login-box{
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        max-width: 25rem;

    }
    .login-text-1{
        padding: 0rem 0rem 0.8rem 0rem;
    }
    
    .login-title{
        padding:0rem 0rem 10rem 0rem;
        font-size: 16px;
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        color: #444;
    }
    .login-title-2{
        font-size: 14px;
        font-family: 'Roboto', sans-serif;
        font-weight: 300;
        color: #444;
    }
    .login-text{
        padding: 2rem 1rem 1rem 1rem;
        color: #666;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 12px;
    }

    .login-button{
        font-family: 'Roboto', sans-serif;
        box-sizing: border-box;
        border: 0;
        box-shadow: none;
        border-width: 1px;
        border-radius: 0;
        background-color: #c23023;
        border-color: #cc0000;
        margin-top: 15px !important;
        margin-bottom: 15px !important;
        border: 1px solid transparent;
        display: block;
        color: #fff;
        font-size: 12px;
        font-weight: 400;
        line-height: 1.42857143;
        text-align: center;
        white-space: nowrap;
        width:100%;
        padding: 3px 6px;
        transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
        cursor:pointer;
    }

    .login-form-text-and-icon{
        display:flex;
    }

    .disabled{
        display: none;
    }
    .login-input-boxes{
        padding-top:1rem;
        width:80%;
    }
}