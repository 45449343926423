.disabled{
    display: none;
}
.acordion{
    margin-top: 1rem;
    background-color: #444444;
    display: flex;
    flex-direction: column;
    color: white;
    text-align: left;
    font-weight: 600;
    font: 'Robot';
    font: 18px;
    padding-left: 3%;
    padding-top:3%;
    padding-right:3%;
    border-radius: 0.2rem;
   
    transition:0.6 ease
}


.acordion-title{
    position: relative;
    padding: 0rem 0rem 0rem 0.5rem;
}
.acordion-text{
    word-wrap: break-word;
    font-weight: 400;
    font: 'Robot';
    font: 15px;
}
.acordion-text-box{
    display: inline-flex;
    flex-direction: column; 


}
.row-1{
    display: inline-flex;
    flex-direction: row;
}

.icon{
    position:relative;
    border-radius: 100px;
    color:'#444444';
    backgroundColor:'white';
}