@media only screen and (min-width: 821px) {
.schema-container {
    width:100%;
    overflow: auto;
    overflow:hidden; 
}
.schema-header {
    overflow:hidden; 
}

.schema-footer {
    position: relative;
    width: 100vw;
    height: 155px;
}

.schema-body {
    overflow:hidden; 
    display:flex;
    justify-content: center;
    align-items: center;
    padding-top:1rem;
    padding-bottom: 1rem;
    overflow: auto;
    min-height: calc(100vh - 297px);
}
}
@media only screen and (max-width: 820px) {
.schema-container {
    width:100%;
    overflow: auto;
    overflow:hidden; 
}
.schema-header {
    overflow:hidden; 
}

.schema-footer {
    position: relative;
    width: 100vw;
    height: 155px;
}

.schema-body {
    overflow:hidden; 
    display:flex;
    /* justify-content: center; */
    align-items: center;
    padding-top:1rem;
    padding-bottom: 1rem;
    /* overflow: auto; */
    min-height: calc(100vh - 297px);
}
}
@media only screen and (max-width: 512px) {
    .schema-container{
        height: 100vh;
        overflow: auto;
    }
    .schema-footer{
        display: flex;
        align-items: end;
    }
    .schema-body {
        overflow:hidden; 
        display:flex;
        justify-content: center;
        align-items: center;
        padding-top:1rem;
        padding-bottom: 1rem;
        overflow: auto;
        min-height: calc(100vh - 297px);
    }
    .schema-header {
        overflow:hidden; 
    }
}