@media only screen and (min-width: 512px) {

    .pageNotRunning-center-label-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 60%;
    width:100%
    }
}
@media only screen and (max-width: 512px) {
    .pageNotRunning-center-label-box{
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 100%;
        width:80%

        }
}
.pageNotRunning-labels{
    align-items: flex-start;
    margin-bottom: 2rem;
}