@media only screen and (min-width: 512px) {
  .container header .header-nav-area #nav_container {
    display: flex;
  }
  .inicio-form {
    display: flex;
    flex-direction: column;
  }
  .inicio-text-box {
    display: inline-flex;
    flex-direction: row;
    gap: 1rem;
  }
  .inicio-row-1 {
    display: flex;
    justify-content: flex-start;
    align-items: left;
    text-align: left;
    font-family: "Roboto", sans-serif;
    color: #333;
    font-size: 22px;
    line-height: 1.8;
  }
  .inicio-row-2 {
    display: flex;
    gap: 1rem;
  }
  .inicio-text-1 {
    font-weight: 600;
    padding-left: 0.5rem;
  }

  .inicio-text-2 {
    color: #777;
    font-weight: lighter;
  }
  .inicio-cita {
    width: 100%;
  }
  .inicio-button-2 {
    background-color: #c23023;
    border-color: #cc0000;
  }

  .inicio-input-box {
    color: #bbb;
    height: 34px;
    opacity: 1;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
    margin: 0.8rem;
    background-color: #fff;
    border: 1px solid #ccc;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  }
  .inicio-button-2 {
    position: relative;
    background-color: #c23023;
    border-color: #cc0000;
  }
  .inicio-button-1 {
    width: 100%;
  }

  .inicio-input-box:focus {
    outline: none !important;
    border: 1px solid #c23023;
  }
}

@media only screen and (width: 834px) {
  .inicio-button-1 {
    width: 30%;
  }
}
/* @media only screen and (min-width: 1300px) {
  .container header .header-nav-area #nav_container {
    display: flex;
  }
  .inicio-form {
    display: flex;
    flex-direction: column;
  }
  .inicio-text-box {
    display: inline-flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  .inicio-row-1 {
    display: flex;
    align-items: left;
    text-align: left;
    font-family: "Roboto", sans-serif;
    color: #333;
    font-size: 22px;
    line-height: 1.8;
  }
  .inicio-row-2 {
    display: flex;
    flex-direction: column;

    gap: 1rem;
  }
  .inicio-text-1 {
    font-weight: 600;
    padding-left: 0.5rem;
  }

  .inicio-text-2 {
    color: #777;
    font-weight: lighter;
  }
  .inicio-cita {
    width: 98%;
  }
  .inicio-button-2 {
    position: relative;
    background-color: #c23023;
    border-color: #cc0000;
  }
  .inicio-button-1 {
    width: 100%;
  }
  .inicio-input-box {
    color: #bbb;
    height: 34px;
    opacity: 1;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
    margin: 0.8rem;
    background-color: #fff;
    border: 1px solid #ccc;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  }
  .inicio-input-acordeon {
  }

  .inicio-input-box:focus {
    outline: none !important;
    border: 1px solid #c23023;
  }
} */
