.input-1-form-box{
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    line-height: 1.42857143;
    color: #666;
    width:100%;

}

.input-1-column-1{
    position: relative;
    display:flex; /* added*/
    flex-direction:column; /* added*/
}
.input-1-row-1{
    display: flex;
    justify-content: space-between;
}
.input-1-label-1{
    display:inline-flex;
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
}

.input-1-input-1{
}

.input-1-box{

    color: #bbb;
    height: 3rem;
    opacity: 1;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
    background-color: #fff;
    border: 1px solid #ccc;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;

}

.input-1-acordeon{
}

.input-1-box:focus {
      outline: none !important;
    border:1px solid #c23023;  }

.input-1-mandatory-text{
    padding-top:0.7rem;
    color: #a94442;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 18px;
}
.input-1-regex-disabled{
    display: none;
}
.input-1-regex-text{
    padding-top:0.7rem;
    color: #a94442;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 18px;
}
.input-1-mandatory-disabled{
    display: none;
}

.react-tel-input .flag-dropdown { display: none; }
.react-tel-input .form-control { padding: 16px 14px 16px 1px !important; border-radius: 0 !important; box-shadow: none !important;}